import { Button } from '@gisce/oficina-virtual-components';
import { ArrowBack, Refresh } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';

export type ErrorPageProps = {
  errorCode?: 401 | 404 | 500 | 503;
  message?: string;
};

export const ErrorPage: FC<ErrorPageProps> = ({
  errorCode = 500,
  message
}) => { 
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const getImageSrcFromErrorCode = (errorCode: number): string | undefined  => {
    switch (errorCode) {
      case 404: return "/images/error/notFound/electrical_tower.jpg";
      case 500: return "/images/error/crash/lightpole.jpg";
      case 503: return "/images/maintenance.jpg";
      default: return "/images/error/crash/lightpole.jpg";
    }
  }

  const getErrorTitleFromCode = (errorCode: number): string => {
    switch (errorCode) {
      case 401: return message ? t(message) : t('common:text.error_page_title');
      case 500: return t('common:text.crash_view_title');
      case 503: return t('common:text.maintenance_view_title');
      default: return t('common:text.error_page_title');
    }
  }

  return <Stack direction="row">
    <Box
      component="img"
      sx={{
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
        [theme.breakpoints.down("lg")]: {
          height: `calc(100vh - 64px)`,
        },
        [theme.breakpoints.up("lg")]: {
          borderRadius: `${theme.custom?.constants?.borderRadius}px 0 0 ${theme.custom?.constants?.borderRadius}px`,
          height: "80vh",
        },
      }}
      src={getImageSrcFromErrorCode(errorCode)}
    />
    <Stack>
      <Box 
        sx={{ 
          [theme.breakpoints.down("md")]: {
            paddingLeft: "7vw",
            paddingRight: "7vw",
            height: `calc(100vh - 64px)`,
            boxSizing: "border-box"
          },
          // [theme.breakpoints.up("md")]: {
          //   padding: 6,
          // },
          [theme.breakpoints.up("md")]: {
            paddingLeft: "5vw",
            paddingRight: "5vw",
          },
          height: "100%", 
          display: "flex", 
          flexDirection: "column", 
          justifyContent: "center"
        }}
      >
        {errorCode === 503 &&
          <Box
            component="img"
            display="flex"
            alignSelf="center"
            sx={{
              [theme.breakpoints.down("md")]: {
                marginTop: 3,
                marginBottom: 3,
              },
              [theme.breakpoints.up("md")]: {
                marginBottom: 6
              },
              width: "75%"
            }}
            src="/images/logo.png"
          />
        }
        <Typography variant="h4" pb={3}>
          {getErrorTitleFromCode(errorCode)}
        </Typography>
        <Typography variant="h6" pb={1}>
          {errorCode && t('common:text.error_code_message', {"errorCode": errorCode})}
        </Typography>
        <Typography variant="body1">
          {errorCode === 404 && t('common:text.notfound_view_message')}
          {errorCode === 500 && t('common:text.crash_view_message')}
          {errorCode === 503 && t('common:text.maintenance_view_subtitle')}
        </Typography>
        {errorCode !== 503 &&
          <Typography variant="body1">
            {t('common:text.error_page_link')}
          </Typography>
        }
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{marginTop: 3}}
          spacing={2}
        >
          {[500, 503].includes(errorCode) &&
            <Button 
              variant="contained" 
              color="primary"
              startIcon={
                <Refresh />
              }
              onClick={() => navigate(0)}
            >
            {t('common:text.error_refresh_button')}
          </Button>}

          {/* Si vull el botó 401 i 403 es pot navegar amb window.location.href perquè recarrega la pàgina. evitaria errors */}
          {[404].includes(errorCode) &&
            <Button 
              variant="contained" 
              color="primary"
              startIcon={
                <ArrowBack />
              }
              onClick={() => navigate("/")}
            >
              {t('common:text.error_go_back_home_button')}
            </Button>}
          </Stack>
      </Box>
    </Stack>
  </Stack>
}
