import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, To } from "react-router-dom";
import { 
	define_token 
} from '../utils/http_functions';
import { default as userService } from "@/services/user";
import { ChangePasswordError } from "@/types/models/services";

const usePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [errorCode, setErrorCode] = useState<ChangePasswordError>();

  const changePasswordAndRedirect = ({
		currentPassword,
		newPassword,
		redirect = t("common:url.invoices")
	}: Parameters<typeof userService.changePassword>[0] & {
		redirect?: To | number;
	}) => {
		setIsLoading(true);
		userService.changePassword({currentPassword, newPassword})
			.then(res => {
				console.log(res);
				define_token(res.token); // És molt possible que això no sigui necessari
				if (typeof redirect === "number") {
					navigate(redirect);
				} else {
					navigate(redirect);
				}
			})
			.catch(err => {
				setErrorCode({
					error: err,
					type: err?.status_code === 403 ? "invalidPassword" : "generic"
				});
			})
			.finally(() => {
				setIsLoading(false);
			})
  };

  return {
    changePasswordAndRedirect,
		isLoading,
		errorCode
  }
}

export default usePassword;