import { Back } from "@gisce/oficina-virtual-components";
import { CouponsView } from "./CouponsView";
import { Stack, Box } from '@mui/material';

interface ILayoutProvider {
	path: string;
  children: React.ReactNode;
}

// This provider checks the url and the current cookies for authentication
export const LayoutProvider = ({
	path,
  children
}: ILayoutProvider) => {

  const locationPaperOptions = {
    sm: {
      breakpoint:'sm', maxWidth: 500
    }
  }

  const hidePaper = [
    'common:url.altHome'
  ];

  const displayPaper = !(hidePaper.indexOf(path) > -1);

  const hideStackPadding = [
    'common:url.error'
  ];

  const displayStackPadding = !(hideStackPadding.indexOf(path) > -1);

  const smContainers = [
    'common:url.activate',
    'common:url.login',
    'common:url.changePassword',
    'common:url.resetPassword',
  ];

  const getPaperOptions = () => {
    const smPapers = [
      'common:url.activate',
      'common:url.login',
      'common:url.changePassword',
      'common:url.resetPassword',
    ];

    if (smPapers.indexOf(path) > -1) {
      return(locationPaperOptions.sm);
    } else {
      return({});
    }
  }

  const isSmallContainer = smContainers.indexOf(path) > -1;

  const showCoupons = [
    'common:url.contracts',
    'common:url.invoices'
  ];

  const displayCoupons = showCoupons.indexOf(path) > -1;

  return <>
		{/* {
			displayCoupons && <CouponsView />
		} */}
		{displayPaper && (
			<Back {...getPaperOptions()}>
				{isSmallContainer ?
					<Box p={4}>
						{children}
					</Box>
				:
					<Box 
						sx={{
              ...(displayStackPadding && {padding: 4})
            }}
					>
						{children}
					</Box>
				}
			</Back>
		)}
		{!displayPaper && children}
	</>

	
}