import React from "react";
import { Field, Form } from "react-final-form";
import { useSelector } from "react-redux";
import { CircularProgress, Button, Box, Typography, Grid } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import _ from "lodash";
import Settings from "../../settings";
import { i18n } from "../../config";
import CheckboxField from "../form-fields/CheckboxField";
import IBANField, { IBANvalid } from "../form-fields/IBANField";


const FormPayment = ({onGoBack, onSubmit}) => {
  const elecNewContract = useSelector((state) => state.newElectricityContract);
  const theme = useTheme();
  const matchesBreakpointMd = useMediaQuery(theme.breakpoints.up('md'));

  const handleGoBack = () => {
    if (onGoBack) {
      onGoBack();
    }
  };

  const handleSubmit = async (values) => {
    await onSubmit(values)
  };

  return (
    <div>
      { _.get(Settings, "newContract.sectionHeaders", false) &&
        <Box m={2} display={"flex"} style={{marginLeft: 0}}>
          <AccountBalanceIcon fontSize="large" color="secondary" style={{padding: 10}}/>
          <Typography variant="h4" style={{alignSelf: "center"}}>{i18n.t('common:text.contractation_about_payment')}</Typography>
        </Box>
      }
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          iban: elecNewContract.iban,
          isOwner: elecNewContract.isOwner,
        }}
        validate={(values) => {
          const errors = {};

          if (!values.iban) {
            errors.iban = i18n.t('common:text.required_field');
          } else if (!IBANvalid(values.iban)) {
            errors.iban = i18n.t('common:text.iban_field_invalid');
          }

          if (!values.isOwner) {
            errors.isOwner = i18n.t('common:text.required_field');
          }

          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={0} style={{marginBottom: 20}}>
              <Grid item xs={12} style={{marginBottom: 10}}>
                <Typography variant="h6" color="primary">
                  {i18n.t('common:text.contractation_about_payment')}
                </Typography>
                <Typography variant="body2">
                  {i18n.t('common:text.contractation_bank_details_info')}
                </Typography>
              </Grid>
              <Box ml={matchesBreakpointMd ? 5 : 0} mr={matchesBreakpointMd ? 5 : 0}>
                <Typography variant="h6">
                  {i18n.t('common:text.contractation_iban_full_name')}
                </Typography>
                <Grid container spacing={3}>
                  <Grid container item xs={12} style={{paddingTop: 0}}>
                    <Grid item xs={6}>
                      <Field
                        name="iban"
                        component={IBANField}
                        style={{width: "100%"}}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{paddingTop: 0}}>
                    <Field
                      name="isOwner"
                      label={
                        <Typography variant="body1" style={{color: "black"}}>
                          {i18n.t('common:text.contractation_owner_confirmation')}
                        </Typography>
                      }
                      component={CheckboxField}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Button
              variant={'text'}
              onClick={() => handleGoBack(values)}
              style={{ marginRight: 12 }}
              disabled={submitting}
            >
              {i18n.t('common:text.contractation_previous')}
            </Button>
            <Button
              type="submit"
              color={'primary'}
              variant={'contained'}
              disabled={submitting}
            >
              {submitting ? <CircularProgress size={25} /> : null}
              {i18n.t('common:text.contractation_next')}
            </Button>
          </form>
        )}
      />
    </div>
  );
};

export default FormPayment;
