import React from "react";
import PropTypes from "prop-types";
import RichContent from "../RichContent";
import { Help } from "@mui/icons-material"

import { Paper, Grid } from "@mui/material"

const style = {
  container: {
    padding: '2rem 2rem 1.75rem',
    marginBottom: '1rem',
  },
  icon: {
    marginRight: '1rem',
    position: 'relative',
    bottom: '2px'
  }
};

export default class HelpMessage extends React.Component {
  render() {
    return (
      <Paper elevation={5} style={style.container}>
        <Grid container justifyContent="flex-start">
          <Help style={style.icon} />
          <RichContent content={this.props.message} />
        </Grid>
      </Paper>
    );
  }
}

HelpMessage.propTypes = {
  message: PropTypes.string,
};
