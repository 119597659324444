import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Login } from "@gisce/oficina-virtual-components";
import Settings from "../settings";
import useAuth from "../hooks/useAuth";
import { RootState } from "@/store";

const LoginView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { login, getErrorMessage } = useAuth();
  const { isAuthenticating } = useSelector((state: RootState) => state.auth );

  const handleSignUp = () => {
    navigate(t('common:url.activate'));
  };

  const handleReset = () => {
    navigate(t('common:url.resetPassword'));
  };

  const handleLogin = (user: string, password: string) => {
    login({type: "Credentials", authParams: {user, password}});
  };

  const error = getErrorMessage();

  return (
    <Login
      image="/images/logo.png"
      alertProps={error ? {severity: "error", label: error} : undefined}
      signUp={Settings?.features?.signup}
      onLogin={handleLogin}
      onSignUp={handleSignUp}
      onReset={handleReset}
      loading={isAuthenticating}
    />
  );
};

export default LoginView;