import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw'
import { executeHooks } from "../../utils/hooks";

export default class RichContent extends React.Component {
  componentDidMount() {
    const { html, content } = this.props;
    if (html && content) {
      executeHooks(content);
    }
  }

  render() {
    const { content, html, ...rest } = this.props;
    if (html) {
      return <ReactMarkdown rehypePlugins={[rehypeRaw]} children={html} />
    }
    return <ReactMarkdown {...rest}>{content}</ReactMarkdown>;
  }
}
